<template>
  <home-template>
    <div class="text-start" slot="content">
      <div class="top-container">
        <v-row class="top-container-title-label">
          <div class="text-title" v-if="isEditMode">Visitor parking</div>
          <div class="text-title" v-else>Add visitor parking</div>
        </v-row>
        <v-row class="tabs tabs-with-paddings">
          <v-tabs v-model="tab">
            <v-tab v-show="showIntagrationTab" class="pl-0">
              Integrations
            </v-tab>
            <v-tab :class="{ 'pl-0': !showIntagrationTab }"> Pricing </v-tab>
            <v-tab> Discounts </v-tab>
            <v-tab> Instructions </v-tab>
            <v-tab v-if="auth.isParkmanAdmin()"> Advanced settings </v-tab>

            <v-col class="button-panel">
              <v-row>
                <v-spacer />
                <v-btn
                  v-if="isPricingTab && auth.isParkmanAdmin()"
                  class="pkmn-button add-price-schedule-button"
                  color="primary"
                  @click="openPricingScheduleForm"
                >
                  Add price schedule
                </v-btn>
              </v-row>
            </v-col>
          </v-tabs>
        </v-row>
      </div>

      <v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-show="showIntagrationTab"
            class="tab-item with-horizontal-padding"
          >
            <v-divider />
            <tab-integrations :hubsList="hubsList" :wardensList="wardensList" />
          </v-tab-item>
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider :class="{ 'pricing-divider': auth.isParkmanAdmin() }" />
            <tab-pricing
              ref="tabPricing"
              :currencyId="locationCurrencyId"
              :prices="schedulePrices"
              @pricigScheduleList="pricigScheduleList = { ...$event }"
              @priceItemChanged="currentPriceItem = $event"
              @edit="editSchedule"
            />
          </v-tab-item>
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
          </v-tab-item>
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-instruction
              @edit="editInstruction"
              :locationId="locationId"
              @openAddForm="openAddForm"
            />
          </v-tab-item>
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <advanced-settings-tab
              ref="tabAdvancedSettings"
              :productSettings="productSettings"
              :locationName="locationName"
              :merchantName="merchantName"
              @changed="productSettingsChanged = $event"
              @validated="productSettingsValid = $event"
              @formData="productSettingsFormData = $event"
              @delete="deleteLocationProduct"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-row>

      <form-set-pricing-schedule
        v-if="isPricingScheduleFormOpenings"
        :editItem="editingScheduleItem"
        :currencyId="locationCurrencyId"
        @close="isPricingScheduleFormOpenings = false"
        @submit="submitPricingSchedule"
        @update="updatePricingSchedule"
        @delete="deletePricingSchedule"
      />
      <add-new-instruction-form
        v-if="isShowingAddForm"
        :editMode="addFormEditMode"
        :instructionTypeId="instructionTypeId"
        :locationId="locationId"
        @close="isShowingAddForm = false"
      />
      <currency-approve-popup
        :showPopup="showCurrencyApprovePopup"
        @approve="sendForReview"
        @close="showCurrencyApprovePopup = false"
      />
      <v-card
        elevation="6"
        class="bottom-navigation"
        v-if="showBottomNavigation"
      >
        <v-card-actions class="justify-end">
          <v-btn
            v-if="showActivatePricing"
            class="pkmn-button mx-1 bottom-button"
            color="primary"
            @click="checkCurrencyApproval"
          >
            Activate pricing
          </v-btn>
          <v-btn
            v-if="false"
            class="pkmn-button mx-1"
            color="warning"
            outlined
            @click="withdrawReview()"
          >
            Withdraw review request & Edit
          </v-btn>
          <v-btn
            v-if="!hideNextBtn"
            class="pkmn-button bottom-button"
            color="primary"
            :outlined="isScheduleInReview ? false : true"
            @click="openNextTab"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        elevation="6"
        class="bottom-navigation"
        v-if="isAdvancedSettingsTab && productSettingsChanged"
      >
        <v-card-actions class="justify-end">
          <v-btn
            v-if="isEditMode"
            class="pkmn-button bottom-button"
            color="primary"
            outlined
            @click="cancelChanges"
          >
            Cancel changes
          </v-btn>
          <v-btn
            v-if="isEditMode"
            class="pkmn-button pkmn-button--has-border bottom-button"
            color="primary"
            :outlined="false"
            :disabled="!productSettingsValid"
            @click="updateProductSettings"
          >
            Save
          </v-btn>
          <v-btn
            v-else
            class="pkmn-button pkmn-button--has-border bottom-button"
            color="primary"
            :outlined="false"
            :disabled="!productSettingsValid"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </home-template>
</template>

<script>
import auth from '@/auth'
import HomeTemplate from '@/templates/Locations'
import TabPricing from '@/components/Locations/AddVisitorParking/Tab/TabPricing.vue'
import AdvancedSettingsTab from '@/components/Locations/AddVisitorParking/Tab/AdvancedSettingsTab.vue'
import FormSetPricingSchedule from '@/components/Locations/AddVisitorParking/Form/FormSetPricingSchedule.vue'
import merchantHelpers from '@/helpers/merchant'
import TabInstruction from '@/components/Locations/Location/InstructionForm.vue'
import AddNewInstructionForm from '@/components/Locations/Location/FormAddNewInstruction.vue'
import CurrencyApprovePopup from '@/components/Locations/AddVisitorParking/CurrencyApprovePopup.vue'
import TabIntegrations from '@/components/Locations/AddVisitorParking/Tab/TabIntegrations.vue'
import * as config from '@/config/config'
import { mapGetters } from 'vuex'

const ACTIVE_STATUS_ID = 3

const TAB = {
  // PAYOUTS: 0,
  DISCOUNTS: 2,
  PRICING: 1,
  INTRODUCTIONS: 3,
  INTERGRATIONS: 0,
  ADVANCED_SETTINGS: 4
}

export default {
  name: 'AddVisitorParking',

  components: {
    HomeTemplate,
    TabPricing,
    FormSetPricingSchedule,
    TabInstruction,
    AddNewInstructionForm,
    AdvancedSettingsTab,
    CurrencyApprovePopup,
    TabIntegrations
  },

  data() {
    return {
      auth: auth,
      isTapPricing: false,
      isPricingScheduleFormOpenings: false,
      tab: '',
      percent: 0,
      currentPriceItem: 0,
      isScheduleInReview: false,
      schedulePrices: [],
      editingScheduleItem: null,
      productSettings: {},
      productSettingsFormData: {},
      productSettingsChanged: false,
      productSettingsValid: false,
      isShowingAddForm: false,
      addFormEditMode: false,
      instructionTypeId: 0,
      pricigScheduleList: {},
      showCurrencyApprovePopup: false,
      hubsList: [],
      wardensList: []
    }
  },

  computed: {
    ...mapGetters([
      'currentProductSettings',
      'currentMerchant',
      'currentLocation'
    ]),
    merchantName() {
      return this.currentMerchant?.name
    },
    locationName() {
      return this.currentLocation?.name
    },
    isPricingActive() {
      return Object.keys(this.pricigScheduleList)?.length
        ? this.pricigScheduleList.schedule[0].status_id === ACTIVE_STATUS_ID
        : false
    },
    locationCurrencyId() {
      if (this.currentLocation) {
        return this.currentLocation.currency_id
      }
      return 0
    },
    isEditMode() {
      return true // TODO add condition when add product functionality is ready
    },
    isPayoutsTab() {
      return false
    },
    isIntegrationTab() {
      return this.tab === TAB.INTERGRATIONS
    },
    isPricingTab() {
      return this.tab === TAB.PRICING
    },
    isIntroductionsTab() {
      return this.tab === TAB.INTRODUCTIONS
    },
    isDiscountsTab() {
      return this.tab === TAB.DISCOUNTS
    },
    isAdvancedSettingsTab() {
      return this.tab === TAB.ADVANCED_SETTINGS
    },
    locationId() {
      return parseInt(this.$route.params.locationId)
    },
    hideNextBtn() {
      return !this.isIntegrationTab
    },
    showActivatePricing() {
      return (
        (this.pricigScheduleList?.weekdayPaidTimes &&
          this.pricigScheduleList?.weekdayPaidTimes?.length) ||
        (this.pricigScheduleList?.sequencePriceSteps?.steps &&
          this.pricigScheduleList?.sequencePriceSteps?.steps?.length) ||
        (this.pricigScheduleList?.periodicPriceItems?.items &&
          this.pricigScheduleList?.periodicPriceItems?.items?.length)
      )
    },
    showBottomNavigation() {
      return (
        !this.isPricingActive &&
        this.isPricingTab &&
        auth.isParkmanAdmin() &&
        (this.showActivatePricing || !this.hideNextBtn)
      )
    },
    showIntagrationTab() {
      // Add checking all lists from intagration tab
      return !!this.hubsList?.length || !!this.wardensList?.length
    },
    wardensUrl() {
      return this.$route.name === 'Merchant'
        ? auth.isParkmanAdmin()
          ? `admin/merchants/${this.$route.params.merchantId}/warden`
          : `merchants/${this.$route.params.merchantId}/warden`
        : auth.isParkmanAdmin()
        ? `admin/merchants/${this.$route.params.merchantId}/locations/${this.$route.params.locationId}/warden`
        : `merchants/${this.$route.params.merchantId}/locations/${this.$route.params.locationId}/warden`
    }
  },

  watch: {
    tab(newValue) {
      if (this.isPayoutsTab) {
        this.percent = 0
      } else if (this.isIntegrationTab) {
        this.percent = 16
      } else if (this.isPricingTab) {
        this.percent = 33
      } else if (this.isIntroductionsTab) {
        this.percent = 49
      } else if (this.isDiscountsTab) {
        this.percent = 66
      } else {
        this.percent = 83
      }
    },
    showIntagrationTab: {
      handler(state) {
        this.tab = state ? 0 : 1
      },
      deep: true
    }
  },

  methods: {
    editInstruction(item) {
      this.addFormEditMode = true
      this.isShowingAddForm = true
      this.instructionTypeId = item.id
    },
    openAddForm(item) {
      this.instructionTypeId = item.id
      this.addFormEditMode = false
      this.isShowingAddForm = true
    },
    openPricingScheduleForm() {
      this.editingScheduleItem = null
      this.isPricingScheduleFormOpenings = true
    },
    editSchedule(item) {
      this.editingScheduleItem = item
      this.isPricingScheduleFormOpenings = true
    },
    async sendForReview() {
      const merchantId = this.$route.params.merchantId
      const scheduleId =
        this.$refs.tabPricing.notExpiredPrices[
          this.$refs.tabPricing.currentItem
        ].id
      await merchantHelpers.submitPricingScheduleForReview(
        merchantId,
        scheduleId
      )
      await merchantHelpers.approvePricingScheduleReview(merchantId, scheduleId)
      if (!this.currentProductSettings.is_currency_disabled) {
        this.fetchProductSettings()
      }
      this.showCurrencyApprovePopup = false
      await this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('pricing_activated')
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
      await this.getPricingSchedule()
      await this.$store.dispatch('setShowOverlay', false)
    },
    withdrawReview() {
      this.isScheduleInReview = false
    },
    async fetchMerchant() {
      await this.$store.dispatch('setShowOverlay', true)
      const merchant = await merchantHelpers.fetchMerchant(
        this.$route.params.merchantId
      )
      if (!merchant) {
        await this.$router.push({ name: 'AllMerchants' })
        await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT_ALL)
        return
      }
      await this.$store.dispatch('setCurrentMerchant', merchant)
    },
    async deleteLocationProduct() {
      const productId = 1 // TODO change the route parameters once the product flow is added
      const locationId = parseInt(this.$route.params.locationId)
      const merchantId = parseInt(this.$route.params.locationId)
      await merchantHelpers.deleteLocationProduct(locationId, productId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'delete',
        message: this.$t('visitor_parking_was_removed')
      })
      await this.$router.push({
        name: 'Location',
        params: { merchantId: `${merchantId}`, locationId: `${locationId}` }
      })
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
    },
    async fetchProductSettings() {
      await this.$store.dispatch('setShowOverlay', true)
      const productId = 1 // TODO change the route parameters once the product flow is added
      const locationId = parseInt(this.$route.params.locationId)
      this.productSettings = await merchantHelpers.getProductAdvancedSettings(
        locationId,
        productId
      )
      this.$store.dispatch('setCurrentProductSettings', this.productSettings)
    },
    async updateProductSettings() {
      const productId = 1 // TODO change the route parameters once the product flow is added
      const locationId = parseInt(this.$route.params.locationId)
      if (this.productSettingsFormData.vat_percentage === "") {
        this.productSettingsFormData.vat_percentage = null
      }
      this.productSettings = {
        ...(await merchantHelpers.updateProductAdvancedSettings(
          locationId,
          productId,
          this.productSettingsFormData
        ))
      }
      this.$store.dispatch('setCurrentProductSettings', this.productSettings)
    },
    async getLocations() {
      const locationId = parseInt(this.$route.params.locationId)
      const locations = await merchantHelpers.getLocationsByMerchant(
        this.$route.params.merchantId
      )
      await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
      await this.$store.dispatch('setMerchantLocations', locations)
      for (let i = 0; i < locations?.length; i++) {
        if (locations[i].id === locationId) {
          const location = locations[i]
          await this.$store.dispatch('setCurrentLocation', location)
          await this.$store.dispatch('setCurrentProduct', 1)
          break
        }
      }
    },
    async submitPricingSchedule(formData) {
      const data = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== null)
      )
      data.location_id = parseInt(this.$route.params.locationId)
      data.product_id = 1
      data.fixed_fee_amount = parseFloat(data.fixed_fee_amount)
      const pricing = await merchantHelpers.createPricingSchedule(
        this.$route.params.merchantId,
        data
      )
      if (pricing) {
        this.schedulePrices.push(pricing)
        this.$refs.tabPricing.currentItem = this.$refs.tabPricing
          ?.notExpiredPrices?.length
          ? this.$refs.tabPricing?.notExpiredPrices?.length
          : 0
        await this.$refs.tabPricing.getPricingDetails(pricing.id)
        this.isPricingScheduleFormOpenings = false
        await this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('schedule_is_added')
        })
        await this.$store.dispatch('setSystemMessage', {
          type: 'warning',
          message: this.$t('remember_to_update_admin_v2_settings')
        })
      }
    },
    async updatePricingSchedule(formData) {
      const data = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== null)
      )
      data.fixed_fee_amount = parseFloat(data.fixed_fee_amount)
      const pricing = await merchantHelpers.updatePricingSchedule(
        this.$route.params.merchantId,
        formData.id,
        data
      )
      if (pricing) {
        const newSchedule = pricing?.schedule[0]
        const index = this.schedulePrices.findIndex(
          (schedule) => schedule.id === newSchedule.id
        )
        if (index !== -1) {
          this.schedulePrices.splice(index, 1, newSchedule)
          this.$refs.tabPricing.priceDetails = pricing
        }
        await this.getPricingSchedule()
        this.isPricingScheduleFormOpenings = false
        await this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('schedule_updated')
        })
        await this.$store.dispatch('setSystemMessage', {
          type: 'warning',
          message: this.$t('remember_to_update_admin_v2_settings')
        })
      }
    },
    async deletePricingSchedule(item) {
      await merchantHelpers.deletePricingSchedule(
        this.$route.params.merchantId,
        item
      )
      await this.getPricingSchedule()
      this.isPricingScheduleFormOpenings = false
      await this.$store.dispatch('setSystemMessage', {
        type: 'error',
        message: this.$t('price_is_removed', { object: 'Schedule price' })
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async getPricingSchedule() {
      if (this.currentLocation === null) {
        await this.fetchMerchant()
        await this.getLocations()
      }
      this.schedulePrices = await merchantHelpers.getPricingSchedule(
        this.$route.params.merchantId,
        parseInt(this.$route.params.locationId)
      )
    },
    async getPricingStatus() {
      await merchantHelpers.getPricingStatus()
    },
    scrollToElement() {
      const locationId = this.$route.params.locationId
      if (!locationId) {
        return
      }

      const el = this.$el.querySelector(
        `.location-list-item[data-id='${locationId}']`
      )

      if (el) {
        el.scrollIntoView()
      }
    },
    cancelChanges() {
      this.productSettings = { ...this.productSettings }
    },
    openNextTab() {
      if (Object.keys(TAB)?.length >= this.tab) {
        this.tab++
      }
    },
    checkCurrencyApproval() {
      if (!this.currentProductSettings.is_currency_disabled) {
        this.showCurrencyApprovePopup = true
      } else {
        this.sendForReview()
      }
    },
    async getHubsList() {
      this.hubsList = await merchantHelpers.getHubsList(
        this.$route.params.locationId
      )
    },
    async getWardensList() {
      this.wardensList = await merchantHelpers.getWardensList(this.wardensUrl)
    }
  },
  async mounted() {
    await this.$store.dispatch('setShowOverlay', true)

    await Promise.all([
      this.getHubsList(),
      this.getWardensList(),
      this.fetchProductSettings(),
      this.getPricingSchedule()
    ])

    await this.$store.dispatch('setShowOverlay', false)

    this.scrollToElement()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.tab-item {
  overflow-y: auto;
  transition: false;
}

.v-window__container {
  height: 100%;
}

.button-panel {
  padding: 0px 68px 40px;
}
.top-container {
  padding-top: 16px !important;
  text-align: start;
  height: 134px !important;
}
.top-container-title-label {
  padding-top: $page-vertical-padding;
  padding-left: 56px;
}

.pricing-divider {
  margin-right: 195px;
}

.warden-divider {
  margin-right: 205px;
}

.bottom-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 180px;
}

.add-price-schedule-button {
  width: 180px;
  height: 46px;
}
</style>
